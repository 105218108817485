<template>
  <van-popup
      v-model="$store.state.special.showNav"
      closeable
      position="left"
      :style="{ width: '8.75rem',height: '100%' }">
    <div class="navLeft">
      <div class="nav-top">
        <div class="nav-top-title">
          <div class="goback-dx" @click="goToGuide">
            章节列表
          </div>
          <div class="name mt_15">
            {{ query.name }}
          </div>
        </div>
      </div>

      <div class="nav-con">
        <ul>
          <template v-for="(item,n) in leftNavInfo.childs">
            <li class="pr_8" :key="n" @click="navClick(item,n,'','no')">
              <div v-if="this_topic == item.name" class="nav-list-con on p_16 ">
                <div>
                  <div class="txt dis_flex dis_sb">
                    {{ n + 1 }}、{{ item.name }}
                    <van-icon class="mt_5" name="arrow"/>
                  </div>
                  <!-- <span class="jindu"><span class="pair" :style="'width:'+ item.stars_count*20 +'%'"></span></span> -->
                  <div class="list-con">
                    <span class="special" @click="handleEvent('daoxue', n)">导学</span>
                    <span class="special mr_0" @click="handleEvent('kaogang', n)">考纲</span>
                    <template v-for="(list,i) in item.childs[0].total">
                      <span v-if="id_num == i && checkOn == n" class="on" :key="i"
                            @click="checkTopic(n,item,i)">{{ list }}</span>
                      <!-- <span v-else-if="item.childs[0].wrongs_idx.split(',').includes(i.toString())" class="err" :key="i"
                            @click="checkTopic(n,item,i)">{{ list }}</span>
                      <span v-else-if="item.childs[0].rights_idx.split(',').includes(i.toString())" class="succ"
                            :key="i" @click="checkTopic(n,item,i)">{{ list }}</span> -->
                      <span v-else :key="i" @click="checkTopic(n,item,i)">{{ list }}</span>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else class="nav-list-con dis_flex dis_sb p_16 ">
                <span class="txt">{{ n + 1 }}、{{ item.name }}</span>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data () {
    return {
      star: 3,
      activeNames: [0],
      activeNames1: [],
      active: '0_0',//菜单选中
      active1: '',//真题菜单选中
      numList: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十'],
      numChange: [],
      thatInfo: {}, //当前选中的信息
      parentName: '',//
      navList: [],
      level: 1,


      query: {},

      this_topic: '',  // 当前所在专题

      checkOn: '',

      login_idx: 0,

      clickNav: false,

      id_num: 0,
      leftNavInfoArr: []
    }
  },
  props: {
    showNum: {
      default: 0
    },
    daoXuan: {
      default: () => {}
    },
    kaogang: {
      default: () => {}
    }
  },
  computed: {
    leftNavInfo () {
      let is_try = this.$route.query.is_try;
      let data;
      if (is_try) {
        data = this.leftNavInfoArr.length>0 && this.leftNavInfoArr[0]
      } else {
        data = this.$store.state.special && this.$store.state.special.leftNavInfo;
      }
      console.log('data', data);
      return data;
    }
  },
  methods: {
    getNavList (type) {
      let name = this.query.book;
      let is_try = this.query.is_try;
      if (is_try) {
        let data = this.leftNavInfoArr
        this.checkTopic(0, data[ 0 ], this.login_idx);

        console.log('el', data[ 0 ]);

        if (type == 1) {
          let isNew = true;
          let this_topic = this.this_topic;
          data.map((el, i) => {
            if (!this_topic && i == 0) {
              this.$store.commit('setThis_topic', el.name);
              this.this_topic = el.name;
            }
            if (el.name == this_topic) {
              isNew = false;
              this.checkTopic(i, el, this.login_idx, true);
            }
          });
          if (isNew) {
            this.checkTopic(0, data[ 0 ], this.login_idx);
          }
        } else {

          this.$store.commit('setThis_topic', el.name);
          this.this_topic = data[ 0 ].name;
          this.$store.commit('setleftNavInfo', data[ 0 ]);

        }
        return false;
      }
      console.log('====>', name)
      window.$api[ name ]({}, res => {
        console.log('res', res);
        let list = res.list;
        let navInfo = {};
        let isNew = true;
        let isNewNum = 0;
        let this_topic = this.this_topic;
        list.map((e, n) => {
          if (e.name === this.query.name) {
            navInfo = e;
            isNewNum = n;
            if (type == 1) { // 刚进来 走这里
              e.childs.map((el, i) => {
                if (!this_topic && i == 0) {
                  this.$store.commit('setThis_topic', el.name);
                  this.this_topic = el.name;
                }
                if (el.name == this_topic) {
                  isNew = false;
                  this.checkTopic(i, el, this.login_idx, true);
                }
              })
            }
          }
        });
        if (isNew && type == 1) {
          this.checkTopic(0, list[ isNewNum ].childs[ 0 ], this.login_idx);
        }

        this.$store.commit('setleftNavInfo', navInfo);
      });
    },
    checkTopic (n, item, i, isTrue) { // n 第几个专题，i 第几题
      this.checkOn = n;
      item.n = n;
      item.current_level = i;

      this.$parent.checkTopic(n);
      // this.user_info_write(item,i);
      this.$store.commit('setNavInfo', item);
      // localStorage.current_level = n;
      this.$store.commit('setNumTopic', i);
      this.$parent.$refs.qianh.navChenge(i);
      this.clickNav = true;
      this.$store.commit('settopicTotal', item.childs[ 0 ].total);
      if (!isTrue) {
        this.id_num = i;
      }

      this.$store.commit('setNav', false);
    },
    navClick (item, n, i, no) {
      let navInfo = item;
      navInfo.n = n;
      this.this_topic = item.name;

      this.$store.commit('settopicTotal', item.childs[ 0 ].total)

      this.$store.commit('setThis_topic', this.this_topic);
      console.log('navInfo--eqweqw-', navInfo);
      if (this.clickNav) {
        this.clickNav = false;
        return false
      }
      if (no == 'no') {
        return false;
      }
      this.$store.commit('setNavInfo', navInfo);

      this.id_num = i || 0;

      // this.user_info_write(navInfo,i)
    },
    // 获取用户当前进度
    async user_info_query () {
      let param = {
        book: this.query.book,
        chapter: this.query.name
      };
      await this.$api.user_info_query({param, method: 'get'}, res => {
        console.log('user_info_query', res);
        let this_topic = res[ '当前专题名称' ];
        this.$store.commit('setThis_topic', this_topic);
        this.this_topic = this_topic;
        this.login_idx = res[ '当前专题索引' ];
        this.id_num = this.login_idx;
        this.getNavList('1');
      });
    },
    getNumBox () {
      let numChange = [];
      let numList = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
      numList.map((e, n) => {
        numChange.push(e)
      })
      // this.numDemo =
    },
    user_info_write (navInfo, exam_idx) {
      let param = {
        zhuanti: navInfo.name,
        dangqianwancheng: '',
        book: this.query.book,
        exam_idx: exam_idx || 0,
      }
      this.$api.user_info_write({param, method: 'post'}, res => {
        console.log('user_info_write-exam_idx-111111', exam_idx);

        this.$store.commit('setNavInfo', navInfo);
      });
    },
    async jindu_query () {
      let query = this.$route.query;
      let param = {
        username: localStorage.username,
        book: query.book
      };
      await this.$api.jindu_query({param, method: 'post'}, res => {
        console.log('jindu_query', res);
        let list = res;
        let obj;
        res.map(e => {
          if (e.zhuanti == "当前进度") {
            obj = e.obj;
          }
        });
        localStorage.leftInfo = obj;
        obj = JSON.parse(obj);
        console.log('obj', obj);
        let all_total = 0;
        let all_trueNumTopic = 0;
        res.map(e => {
          if (obj.data.name == e.zhuanti) {
            let data = JSON.parse(e.obj);
            data = data.data;
            let arr = data.childs;
            arr.map((el, i) => {
              all_total += el.total;
              all_trueNumTopic += (el.trueNumTopic || 0);
              if (el.total == el.trueNumTopic) {
                if (i + 1 == arr.length) {
                  this.level = i + 1;
                } else {
                  this.level = i + 2;
                }
              } else {
                this.level = i + 1;
              }
            })
          }
        });
        let start = (all_trueNumTopic / all_total) * 5;
        start = parseInt(start);
        this.star = start;
      });
    },
    async getLevel () {
      let param = {
        username: localStorage.username
      };
      await this.$api.jindu_query({param, method: 'post'}, res => {
        let list = res;
        let obj;
        res.map(e => {
          if (e.zhuanti == "当前进度") {
            obj = e.obj;
          }
        });
        localStorage.leftInfo = obj;
        obj = JSON.parse(obj);
        console.log('obj', obj);
        let all_total = 0;
        let all_trueNumTopic = 0;
        res.map(e => {
          if (obj.data.name == e.zhuanti) {
            let data = JSON.parse(e.obj);
            data = data.data;
            let arr = data.childs;
            arr.map((el, i) => {
              all_total += el.total;
              all_trueNumTopic += (el.trueNumTopic || 0);
              if (el.total == el.trueNumTopic) {
                if (i + 1 == arr.length) {
                  this.level = i + 1;
                } else {
                  this.level = i + 2;
                }
              } else {
                this.level = i + 1;
              }
            })
          }
        });
      })
    },
    goToGuide () {
      let query = this.$route.query;
      this.$router.push({path: '/guide', query: {name: query.book}})
      setTimeout(() => {
        location.reload();
      }, 100);
    },

    handleEvent (type, index) {
      const methodMap = {
        daoxue: this.daoXuan,
        kaogang: this.kaogang
      }
      methodMap[type](index)
    }
  },
  async mounted () {
    // let query = this.$route.query;
    // console.log('query---------', query);
    // this.query = query;

    // await this.user_info_query();

    this.$api.free({method: 'get'}).then(res=>{
      this.leftNavInfoArr = res.list
      console.log(111, this.leftNavInfoArr);
      this.$store.commit('setleftNavInfo', this.leftNavInfoArr[0]);

      this.clickNav = true;
      this.navClick(this.leftNavInfoArr[0].childs[0],0,'','no')
      this.checkTopic(0, this.leftNavInfoArr[0].childs[0], 0)
    })

  },
}
</script>

<style lang="less" scoped>
.navLeft {
  .nav-top {
    padding: 48px 16px 24px 16px;

    &-title {
      .goback-dx {
        height: 24px;
        font-size: 16px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #3364FF;
        line-height: 24px;
      }

      .name {
        height: 29px;
        font-size: 20px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #2A3155;
        line-height: 29px;
      }
    }
  }

  .nav-con {
    ul {
      li {
        .nav-list-con {
          width: 248px;
          min-height: 51px;
          background: rgba(65, 95, 213, 0.1);
          border-radius: 12px;
          box-sizing: border-box;
          margin-left: 16px;
          margin-bottom: 12px;

          &.on {
            color: #fff;
            min-height: 80px;
            background: #415FD5;

            .jindu {
              width: 100%;
            }
          }

          .list-con {
            display: flex;
            flex-wrap: wrap;
            max-height: 135px;
            overflow: auto;

            span {
              width: 32px;
              height: 32px;
              line-height: 32px;
              border-radius: 32px;
              margin: 10px;
              color: #fff;
              text-align: center;
              border: 1px solid #fff;

              &.on {
                color: #fff;
                background: #F9C200;
                border: 1px solid #F9C200;
              }

              &.err {
                color: #fff;
                background: red;
                border: 1px solid red;
              }

              &.succ {
                // color: #415FD5;
                background: #07c160;
                border: 1px solid #07c160;
              }
            }

            .special {
              width: 89px;
            }
          }
        }

        .jindu {
          // margin-top: 12px;
          width: 60px;
          height: 8px;
          background: #fff;
          border-radius: 5px;
          display: inline-block;
          // overflow: hidden;
          position: relative;

          .pair {
            background: #F9C200;
            position: absolute;
            border-radius: 5px;
            top: 0.5px;
            left: 0px;
            height: 8px;
          }
        }
      }
    }
  }
}

.mr_0 {
  margin-right: 0px !important;
}
</style>
